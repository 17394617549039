import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileDetails: {},
  subscriptionDetails: [],
  selectedPrintItems: [],
};

export const slice = createSlice({
  name: "printHouseProfile",
  initialState,
  reducers: {
    setPrintHouseProfileData: (state, action) => {
      state.profileDetails = { ...state.profileDetails, ...action.payload };
    },
    setPrintHouseProfileSubscriptionData: (state, action) => {
      state.subscriptionDetails = action.payload;
    },
    setSelectedPrintItems: (state, action) => {
      state.selectedPrintItems = action.payload;
    },
    deletePrintableItem: (state, action) => {
      let _newItems = state.selectedPrintItems;
      console.log(action.payload);
      _newItems = _newItems.filter((item) => item.id != action.payload);
      state.selectedPrintItems = _newItems;
      console.log(state.selectedPrintItems);
    },
  },
});

export const {
  deletePrintableItem,
  setPrintHouseProfileData,
  setPrintHouseProfileSubscriptionData,
  setSelectedPrintItems,
} = slice.actions;

export default slice.reducer;
