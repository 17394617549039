import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useDispatch } from "react-redux";
import { store } from "./features/store";
import { createTheme, ThemeProvider } from "@mui/material";
import { endUserLogin, login } from "./features/auth.slice";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./utils/ScrollToTop";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto"].join(","),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <AuthHandler />
        </Router>
        <Toaster />
        {/* <ModalHandler /> */}
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

function AuthHandler() {
  const dispatch = useDispatch();

  useEffect(() => {
    const _authenticated = localStorage.getItem("isAuthenticated");
    const _user = localStorage.getItem("user");
    const authenticated = JSON.parse(_authenticated ?? "null") ?? false;
    const user = JSON.parse(_user ?? "null");
    const userType = localStorage.getItem("userType");
    const endUserToken = localStorage.getItem("token");
    const endUserTemJson = localStorage.getItem("temp_json_path");

    if (authenticated) {
      if (user) {
        dispatch(login(user));
      } else {
        dispatch(
          endUserLogin({ token: endUserToken, temp_json_path: endUserTemJson })
        );
      }
    }
  }, []);

  return <App />;
}

reportWebVitals();
