import React, { useEffect, useLayoutEffect } from "react";
import "./App.css";
import "@splidejs/react-splide/css";
import { Routes, Route } from "react-router-dom";
import {
  unAuthRoutesList,
  dtpRoutesList,
  dtpOrderDetailsList,
  dtpAddNewOrderList,
  clientRoutesList,
  clientEditList,
  clientOrderDetailsList,
  superAdminRoutesList,
  superAdminAddNewList,
  superAdminDetailsList,
  printHouseRoutesList,
  printHouseOrderDetailsList,
  printHouseAddNewOrderList,
  endUserRoutesList,
} from "./routes/routes";
import { Suspense } from "react";
import DashboardLayouts from "./layouts/DashboardLayouts";
import OrderDetailsLayout from "./layouts/OrderDetailsLayout";
import AddNewOrdersLayout from "./layouts/AddNewOrdersLayout";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "./assets";
import { api } from "./api/apis";
import { setAllRoles } from "./features/masters.slice";
import { setPrintHouseProfileData } from "./features/printHouse/printHouseProfile.slice";
import { setClientProfileData } from "./features/client/clientProfile.slice";
import { setXApiKey } from "./api/instance";

function App() {
  console.log(process.env.REACT_APP_ENV);
  console.log(process.env.REACT_APP_API_URL);
  console.log(process.env.REACT_APP_ENDUSER_URL);

  useLayoutEffect(() => {
    setXApiKey(process.env.REACT_APP_X_API_KEY);
  }, []);

  const dispatch = useDispatch();
  const { isAuthenticated, userType } = useSelector((state: any) => state.auth);
  React.useEffect(() => {
    if (userType === 1) {
      api
        .getAllRoles()
        .then((res) => {
          const allRoles = res.data.payload;
          dispatch(setAllRoles(allRoles));
        })
        .catch((err) => console.error(err));
    }

    if (
      isAuthenticated === true &&
      (userType == 1 || userType == 2 || userType == 3 || userType == 4)
    ) {
      if (userType === 2) {
        api
          .getUserProfile()
          .then(({ data }) => {
            dispatch(setPrintHouseProfileData(data.data));
          })
          .catch((err) => console.error(err));
      }
      if (userType === 4) {
        api
          .getUserProfile()
          .then(({ data }) => {
            console.log("profile CL");
            dispatch(setClientProfileData(data.data));
          })
          .catch((err) => console.error(err));
      }
    }
  }, [userType, isAuthenticated]);

  if (!isAuthenticated) {
    return <UnAuthorizedRoutes />;
  }
  console.log(" user role updated ", isAuthenticated);
  switch (userType) {
    case 1:
      return (
        <>
          <SuperAdminRoutes />
          <SuperAdminAdd />
          <SuperAdminDetails />
        </>
      );
    case 2:
      return (
        <>
          <PrintHouseRoutes />
          <PrintHouseAddNewOrders />
          <PrintHouseOrderDetails />
        </>
      );
    case 3:
      return (
        <>
          <DtpRoutes />
          <AddNewOrder />
          <DtpOrderDetails />
        </>
      );
    case 4:
      return (
        <>
          <ClientRoutes />
          <ClientEdit />
          <ClientDetails />
        </>
      );
    case 5:
      return (
        <>
          <EndUserRoutes />
        </>
      );
    default:
      return (
        <>
          <EndUserRoutes />
        </>
      );
  }
}

// Login and Register
const UnAuthorizedRoutes = () => {
  return (
    <Routes>
      {unAuthRoutesList.map((routeKeyValueArray): any => {
        const route = routeKeyValueArray[1];
        const { paths, key, element, suspense, fallback } = route;
        return paths.map((_sinlgePath: string) => {
          return (
            <Route
              key={key}
              path={_sinlgePath}
              element={
                suspense ? (
                  <Suspense {...{ fallback }}>{element}</Suspense>
                ) : (
                  element
                )
              }
            />
          );
        });
      })}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

// DTP
const DtpRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayouts />}>
        {dtpRoutesList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_singlePath: string) => {
            return (
              <Route
                key={key}
                path={_singlePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const AddNewOrder = () => {
  return (
    <Routes>
      <Route element={<AddNewOrdersLayout />}>
        {dtpAddNewOrderList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const DtpOrderDetails = () => {
  return (
    <Routes>
      <Route element={<OrderDetailsLayout />}>
        {dtpOrderDetailsList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

// PrintHouse
const PrintHouseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayouts />}>
        {printHouseRoutesList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_singlePath: string) => {
            return (
              <Route
                key={key}
                path={_singlePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const PrintHouseAddNewOrders = () => {
  return (
    <Routes>
      <Route element={<AddNewOrdersLayout />}>
        {printHouseAddNewOrderList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const PrintHouseOrderDetails = () => {
  return (
    <Routes>
      <Route element={<OrderDetailsLayout />}>
        {printHouseOrderDetailsList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

// End User
const EndUserRoutes = () => {
  return (
    <Routes>
      {endUserRoutesList.map((routeKeyValueArray): any => {
        const route = routeKeyValueArray[1];
        const { paths, key, element, suspense, fallback } = route;
        return paths.map((_singlePath: string) => {
          return (
            <Route
              key={key}
              path={_singlePath}
              element={
                suspense ? (
                  <Suspense {...{ fallback }}>{element}</Suspense>
                ) : (
                  element
                )
              }
            />
          );
        });
      })}
    </Routes>
  );
};

// Client
const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayouts />}>
        {clientRoutesList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_singlePath: string) => {
            return (
              <Route
                key={key}
                path={_singlePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const ClientEdit = () => {
  return (
    <Routes>
      <Route element={<AddNewOrdersLayout />}>
        {clientEditList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const ClientDetails = () => {
  return (
    <Routes>
      <Route element={<OrderDetailsLayout />}>
        {clientOrderDetailsList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

// Super Admin
const SuperAdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayouts />}>
        {superAdminRoutesList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_singlePath: string) => {
            return (
              <Route
                key={key}
                path={_singlePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const SuperAdminAdd = () => {
  return (
    <Routes>
      <Route element={<AddNewOrdersLayout />}>
        {superAdminAddNewList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const SuperAdminDetails = () => {
  return (
    <Routes>
      <Route element={<OrderDetailsLayout />}>
        {superAdminDetailsList.map((routeKeyValueArray): any => {
          const route = routeKeyValueArray[1];
          const { paths, key, element, suspense, fallback } = route;
          return paths.map((_sinlgePath: string) => {
            return (
              <Route
                key={key}
                path={_sinlgePath}
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
      </Route>
    </Routes>
  );
};

export default App;
