import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientProfileDetails: {},
};

export const slice = createSlice({
  name: "clientProfile",
  initialState,
  reducers: {
    setClientProfileData: (state, action) => {
      state.clientProfileDetails = {...state.clientProfileDetails,... action.payload};
    },
  },
});

export const {setClientProfileData} = slice.actions;

export default slice.reducer;
