import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientData: [],
};

export const slice = createSlice({
  name: "dtpClient",
  initialState,
  reducers: {
    setClientData: (state, action) => {
      state.clientData = action.payload;
    },
    enableDisableUser: (state, action) => {
      const user = action.payload;
      console.log(user, "user");
      const _newUserList = state.clientData;
      if (_newUserList?.length > 0) {
        const _foundUser = _newUserList.find(
          (_user) => _user.user_id === user.user_id
        );
        _foundUser.is_active = !_foundUser.is_active;
        state.clientData = _newUserList;
      }
    },
    deleteUser: (state, action) => {
      state.clientData = state.clientData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const { deleteUser, enableDisableUser, setClientData } = slice.actions;

export default slice.reducer;
