import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: [],
};

export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUpdateUser: (state, action) => {
      // TODO : handle edit functionality later
    },
    enableDisableUser: (state, action) => {
      const user = action.payload;
      const _newUserList = state.userData;
      if (_newUserList?.length > 0) {
        const _foundUser = _newUserList.find((_user) => _user.id === user.id);
        _foundUser.is_active = !_foundUser.is_active;
        state.userData = _newUserList;
      }
    },
    deleteUser: (state, action) => {
      state.userData = state.userData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const { deleteUser, enableDisableUser, setUserData } = slice.actions;

export default slice.reducer;
