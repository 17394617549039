import { createSlice } from "@reduxjs/toolkit";
import { setAuthorizationToken, setEndUserToken } from "../api/instance";

const initialState = {
  isAuthenticated: null,
  userType: null,
  username: "",
  email: "",
  access: "",
  refresh: "",
  json: "",
  token: "",
  temp_json_path: "",
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    endUserLogin: (state, action) => {
      const { token, temp_json_path } = action.payload;
      setEndUserToken(token);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("token", token);
      localStorage.setItem("temp_json_path", temp_json_path);
      localStorage.setItem("userType", 5);
      state.isAuthenticated = true;
      state.token = token;
      state.temp_json_path = temp_json_path;
      state.userType = 5;
    },
    endUserLogout: (state) => {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("temp_json_path");
      localStorage.removeItem("userType");
      state.isAuthenticated = initialState.isAuthenticated;
      state.token = initialState.token;
      state.temp_json_path = initialState.temp_json_path;
      state.userType = initialState.userType;
    },
    login: (state, action) => {
      const { role_id, username, email, access, refresh, token } =
        action.payload;
      setAuthorizationToken(access);
      localStorage.setItem("authToken", access);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("user", JSON.stringify(action?.payload));
      state.isAuthenticated = true;
      state.userType = role_id;
      state.username = username;
      state.email = email;
      state.access = access;
      state.refresh = refresh;
      state.json = action.payload.temp_json;
    },
    logOut: (state) => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      state.isAuthenticated = initialState.isAuthenticated;
      state.userType = initialState.role_id;
      state.username = initialState.username;
      state.email = initialState.email;
      state.access = initialState.access;
      state.refresh = initialState.refresh;
      state.json = initialState.json;
    },
  },
});

export const { endUserLogin, endUserLogout, login, logOut } = slice.actions;

export default slice.reducer;
