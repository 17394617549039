import { createContext } from "react";

import {
  endUserAxiosInstance,
  openAxiosInstance,
  securedAxiosInstance,
} from "./instance";

const masterAPIS = {
  getAllRoles: async () => {
    return securedAxiosInstance.get("/common/api/roles/");
  },

  getStates: async (countryId) => {
    return securedAxiosInstance.get(
      `/common/api/countries/${countryId}/states/`
      // `/common/api/countries/101/states/`
    );
  },

  getCountries: async () => {
    return securedAxiosInstance.get("/common/api/countries/");
  },

  getCities: async (stateId) => {
    return securedAxiosInstance.get(`/common/api/states/${stateId}/cities/`);
    // return securedAxiosInstance.get(`/common/api/states/22/cities/`);
  },

  getPrintItemsTypes: async () => {
    return securedAxiosInstance.get("/printitems/types/listing/");
  },
};

const commonAPIs = {
  getUserByID: async (id) =>
    securedAxiosInstance.get(`/user/list/?user_id=${id}`),

  getOrderListingWithFilters: async (value) =>
    securedAxiosInstance.post(`/orders/listing`, value),

  getUserProfile: async () => securedAxiosInstance.get(`/user/profile/`),

  getOrderListing: async (order_status) =>
    securedAxiosInstance.post(`/orders/listing`, {
      globalFilter: "",
      limit: 1000,
      skip: 0,
      sortField: "",
      sortOrder: 1,
      order_status,
    }),
  // Update
  updateOrderStatus: async (id, value) =>
    securedAxiosInstance.post(`/orders/status/update/${id}`, value),

  updateUserProfile: async (values) =>
    securedAxiosInstance.post(`/user/profile/`, values),

  updateUserByID: async (id, values) =>
    securedAxiosInstance.post(`/user/update/${id}`, values),

  updateBillingData: async (id, values) =>
    securedAxiosInstance.post(`/superadmin/billing/update/${id}/`, values),

  updateSubscriptionPlan: async (id, values) =>
    securedAxiosInstance.post(`/subscriptions/update/${id}/`, values),

  updateSuperAdminPrintItems: async (id, values) =>
    securedAxiosInstance.post(`/printitems/update/${id}`, values),

  // Delete
  deleteUser: async (id) => securedAxiosInstance.post(`/user/delete/${id}/`),

  deletePrintItems: async (id) =>
    securedAxiosInstance.get(`/printitems/delete/${id}/`),

  deletPrintItemsAPI: async (id) => {
    return securedAxiosInstance.get(`/printitems/delete/${id}/`);
  },

  deleteSubscriptionPlan: async (id) => {
    return securedAxiosInstance.post(`/subscriptions/delete/${id}/`);
  },

  // Enable or Disable
  enableDisableUser: async (id, status) => {
    return securedAxiosInstance.post(`/user/changestatus/${id}/`, { status });
  },

  enableDisableSubscription: async (id, status) => {
    return securedAxiosInstance.post(`/subscriptions/changeStatus/${id}/`, {
      status,
    });
  },

  enableDisablePrintItems: async (id, status) => {
    return securedAxiosInstance.post(`/printitems/changestatus/${id}/`, {
      status,
    });
  },
  getSingleBatchData: async (id) => {
    return securedAxiosInstance.get(`/orders/batch/get_data/${id}`);
  },

  // Enduser verify listing
  endUserVerifyData: async (id, data) => {
    return securedAxiosInstance.post(`/orders/${id}/enrolment/listing`, data);
  },
};

const authAPIs = {
  login: async (email, password) => {
    return openAxiosInstance.post("/user/login/", { email, password });
  },

  forgotPassword: async (email) => {
    return openAxiosInstance.post("/user/send-otp-email/", { email });
  },

  changePassword: async (email, otp, password) => {
    return openAxiosInstance.post("/user/forgot_password/", {
      email,
      otp,
      password,
    });
  },

  endUserLogin: async (data) => {
    return openAxiosInstance.post("/enrolment/login", data);
  },
};

const superAdminAPIs = {
  // Details
  getAllPrinterUser: async () => {
    return securedAxiosInstance.get("/user/list/");
  },

  getBillingDetails: async () => {
    return securedAxiosInstance.get("/superadmin/billing/all/");
  },

  getBillingHistory: async () => {
    return securedAxiosInstance.get("/superadmin/billing/paid_history/");
  },

  getSubscriptionDetails: async () => {
    return securedAxiosInstance.get("/subscriptions/");
  },

  getPrintItemsDetails: async () => {
    return securedAxiosInstance.get("/printitems/?offset=0&limit=10");
  },

  getSubscriptionActiveList: async () => {
    return securedAxiosInstance.get("/subscriptions/active/");
  },

  getSingleBillingInfo: async (id) => {
    return securedAxiosInstance.get(`/superadmin/billing/get/${id}/`);
  },

  getSinglePrintItemInfo: async (id) => {
    return securedAxiosInstance.get(`/printitems/${id}`);
  },

  getSubscriptionBillingCycle: async () =>
    securedAxiosInstance.get("/subscriptions/billingchoices/listing/"),

  getSubscriptionBillingBasis: async () =>
    securedAxiosInstance.get("/subscriptions/billingbasis/listing/"),

  getSingleSubscriptionInfo: async (id) =>
    securedAxiosInstance.get(`/subscriptions/${id}/`),

  getPrintHouseUserList: async () =>
    securedAxiosInstance.get("/user/list/?offset=0&limit=10&role_id=2"),

  getDTPLists: async () => {
    return securedAxiosInstance.get("/user/list/?offset=0&limit=10&role_id=3");
  },

  // Create
  createPrinthouse: async (printhousedata) => {
    return securedAxiosInstance.post("/user/create/", printhousedata);
  },

  createDtp: async (dtpdata) => {
    return securedAxiosInstance.post("/user/create/", dtpdata);
  },

  createClient: async (clientdata) => {
    return securedAxiosInstance.post("/user/create/", clientdata);
  },

  createSubscriptionPlan: async (subscriptiondata) => {
    return securedAxiosInstance.post("/subscriptions/add", subscriptiondata);
  },

  createPrintItem: async (itemdata) => {
    return securedAxiosInstance.post("/printitems/add/", itemdata);
  },
  uploadClientLogo: async (data) => {
    return securedAxiosInstance.put("/user/update-profile-pic/", data);
  },
};

const printHouseAPIs = {
  //get request
  getAllPrintHouseorders: async () => {
    return securedAxiosInstance.get("/orders/?offset=0&limit=10");
  },
  getAllDTPLists: async () => {
    return securedAxiosInstance.get("/user/list/?offset=0&limit=10&role_id=3");
  },
  getPrintHouseBillingHistory: async () => {
    return securedAxiosInstance.get("/printer/billing/history/");
  },
  getPrintHouseProfileSubscriptions: async () => {
    return securedAxiosInstance.get("/subscriptions/printer/sub_details/");
  },
  getSingleBillingInfo: async (id) => {
    return securedAxiosInstance.get(`/superadmin/billing/get/${id}/`);
  },
  getSelectedPrintItems: async () => {
    return securedAxiosInstance.get(`/printitems/get/printables`);
  },
  getSinglePrintItems: async (id) => {
    return securedAxiosInstance.get(`/printitems/${id}`);
  },
  getActivePrintItems: async () => {
    return securedAxiosInstance.get(`/printitems/active/data`);
  },
  getbatchDownload: async (id) => {
    return securedAxiosInstance.get(`/orders/batch/download/${id}`);
  },
  deletePrintItems: async (id) => {
    return securedAxiosInstance.delete(`/printitems/get/printables/${id}`);
  },
  // Post Request
  setAddNewDtp: async (data) => {
    return securedAxiosInstance.post("/user/create/", data);
  },
  updateProfileInfo: async (data) => {
    return securedAxiosInstance.post("/user/profile/", data);
  },
  updatePrintItems: async (data) => {
    return securedAxiosInstance.post("/printitems/update/printables", data);
  },
  updateOrganizationLogo: async (data) => {
    return securedAxiosInstance.put("/user/profile/", data);
  },
  getBatchListing: async (data) => {
    return securedAxiosInstance.post(`/orders/batch/listing`, data);
  },
  foreBatchCloseUpdate: async (data) => {
    return securedAxiosInstance.post(`/orders/batch/foreclose`, data);
  },
  cancelOrder: async (data) => {
    return securedAxiosInstance.post("/orders/cancel", data);
  },
  cancelBatch: async (data) => {
    return securedAxiosInstance.post("/orders/batch/cancel", data);
  },
};

const clientAPIs = {
  setAddNewOrder: async (data) => {
    return securedAxiosInstance.post("/orders/add", data);
  },
  getPrintItemsForOrder: async () => {
    return securedAxiosInstance.get("/orders/get_print_items");
  },
  getSingleOrder: async (id) => {
    return securedAxiosInstance.get(`/orders/details/${id}`);
  },
  enrolmentVerification: async (OrderId, enrolmentId) => {
    return securedAxiosInstance.get(
      `/orders/${OrderId}/enrolment/${enrolmentId}/verify`
    );
  },
  enrolmentDelete: async (OrderId, enrolmentId) => {
    return securedAxiosInstance.delete(
      `/orders/${OrderId}/enrolment/${enrolmentId}/delete`
    );
  },
};

const dtpAPIs = {
  // Details
  getClientList: async () => {
    return securedAxiosInstance.post("/user/listing/");
  },
  getOrderClientList: async () => {
    return securedAxiosInstance.get("/orders/get_clients");
  },
  getOrderPrintItemList: async () => {
    return securedAxiosInstance.get("/orders/get_print_items");
  },

  uploadOrderTemplate: async (id, data) => {
    return securedAxiosInstance.post(`/orders/upload/${id}`, data);
  },
  // Create
  dtpAddNewOrder: async (data) => {
    return securedAxiosInstance.post("/orders/add", data);
  },

  // EndUser Submit Fill Order
  endUserUploadData: async (data) => {
    return endUserAxiosInstance.put("/enrolment/save", data);
  },

  endUserRawDataSubmit: async (data) => {
    return endUserAxiosInstance.post("/enrolment/save-raw", data);
  },

  // Fonts Api
  getAllActiveFonts: async () => {
    return securedAxiosInstance.get("/orders/fonts/list");
  },

  addFont: async (data) => {
    return securedAxiosInstance.post("/orders/fonts/upload", data);
  },
};

export const api = {
  ...masterAPIS,
  ...authAPIs,
  ...commonAPIs,
  ...superAdminAPIs,
  ...printHouseAPIs,
  ...clientAPIs,
  ...dtpAPIs,
};

export const Context = createContext();
