import React from "react";
import { FormGroup, Paper, Typography } from "@mui/material";
import { ErrorMessage, useField } from "formik";

const TextField = ({ label, ...props }: any) => {
  const [field] = useField(props);
  return (
    <FormGroup>
      <Typography
        variant="body1"
        sx={{ color: props.color ? "#FFFFFF" : "#283C92", marginBottom: "4px" }}
      >
        {label}
      </Typography>
      <input className="search-bar" {...props} />
      <ErrorMessage
        component="div"
        name={field.name}
        className="error-message"
      />
    </FormGroup>
  );
};

export default TextField;
