import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  all_roles: [],
  cities: [],
  states: [],
  countries: [],
};

export const slice = createSlice({
  name: "masters",
  initialState,
  reducers: {
    setAllRoles: (state, action) => {
      state.all_roles = action.payload;
    },

    setCities: (state, action) => {
      state.cities = action.payload;
    },

    setStates: (state, action) => {
      state.states = action.payload;
    },

    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const { setAllRoles, setCities, setStates, setCountries } =
  slice.actions;

export default slice.reducer;
