import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderData: [],
  orderHistory: [],
};

export const slice = createSlice({
  name: "dtpOrder",
  initialState,
  reducers: {
    setPrintHouseOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setPrinthouseOrderHistory: (state, action) => {
      state.orderHistory = action.payload;
    },
  },
});

export const {
    setPrintHouseOrderData,
    setPrinthouseOrderHistory
} = slice.actions;

export default slice.reducer;
