import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth.slice";
import masterReducer from "./masters.slice";
import superadminReducer from "./superadmin/superadmin.slice";
import billingReducer from "./superadmin/billing.slice";
import subscriptionReducer from "./superadmin/subscription.slice";
import printitemsReducer from "./superadmin/printitems.slice";
import printhouseOrderListReducer from "./printHouse/printHouseOrder.slice";
import printhouseDtpListReducer from "./printHouse/printHouseDtp.slice";
import printHouseBillingReducer from "./printHouse/printHouseBilling.slice";
import printHouseProfileReducer from "./printHouse/printHouseProfile.slice";
import clientProfileReducer from "./client/clientProfile.slice";
import clientOrderReducer from "./client/clientOrder.slice";
import endUserReducer from "./endUser/endUser.slice";
import dtpReducer from "./dtp/dtpclient.slice";
import dtpOrderReducer from "./dtp/dtpOrdre.slice";
import forgotPasswordReducer from "./forgotPassword.slice";
import canvasReducer from "./imageeditor/canvasDataSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    master: masterReducer,
    superadmin: superadminReducer,
    billing: billingReducer,
    subscription: subscriptionReducer,
    printitems: printitemsReducer,
    printHouseOrder: printhouseOrderListReducer,
    printHouseDtp: printhouseDtpListReducer,
    printHouseBilling: printHouseBillingReducer,
    printHouseProfile: printHouseProfileReducer,
    clientOrder: clientOrderReducer,
    clientProfile: clientProfileReducer,
    dtpClient: dtpReducer,
    dtpOrder: dtpOrderReducer,
    endUser: endUserReducer,
    forgotPassword: forgotPasswordReducer,
    canvasJson: canvasReducer,
  },
});
