import React, { Suspense } from "react";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import routes, { dtpRoutes } from "../routes/routes";
import LogoutButton from "../components/LogoutButton";
import logo from "../assets/logo.png";

function AddNewOrdersLayout() {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        p={2}
        zIndex={2000}
        sx={{
          position: "sticky",
          backgroundColor: "#FFFFFF",
          top: 0,
          justifyContent: "space-between",
        }}
      >
        <Link
          // to={dtpRoutes.dtpDashboard.paths[0]}
          to="/" // Remove this and Uncomment 👆 when Dashboard story is available
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={logo}
            alt="logo"
            height={60}
            style={{ transition: "all 0.3s ease" }}
          />
        </Link>
        <LogoutButton />
      </Stack>
      <Box>
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

export default AddNewOrdersLayout;
