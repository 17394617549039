import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientOrderDetails: [],
  clientOrderHistoryDetails:[],
  clientSingleOrder:{},
  clientOrderChanged : true,
};

export const slice = createSlice({
  name: "clientOrder",
  initialState,
  reducers: {
    setClientOrderData: (state, action) => {
      // state.clientOrderDetails = [...new Set([...state.clientOrderDetails,...action.payload])];
      state.clientOrderDetails = action.payload;
    },
    setClientSingleorderData: (state, action) => {
      state.clientSingleOrder= action.payload;
    }, 
    setClientOrderHistoryData: (state, action) => {
      state.clientOrderHistoryDetails= action.payload;
    },
    setOrderChanged:(state, action) => {
      state.clientOrderChanged = action.payload;
    } 
  },
});

export const {setClientOrderData , setClientSingleorderData,setClientOrderHistoryData , setOrderChanged} = slice.actions;

export default slice.reducer;