import React, { useState, Suspense } from "react";
import { Box } from "@mui/material";
import Sidebar from "../components/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { superAdminDetailsList } from "../routes/routes";

function DashboardLayouts() {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(location.pathname);
  React.useLayoutEffect(() => {
    document.body.style.overflow = "auto !important";
  });

  return (
    <>
      <Box
        sx={{
          px: { sx: 0, md: 0 },
          backgroundColor: "#283C92",
        }}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          bgcolor: "#F5F6FA",
        }}
        marginLeft={{ xs: 0, md: "240px" }}
        marginTop="90px"
        p={2}
        overflow="scroll !important"
      >
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

export default DashboardLayouts;
