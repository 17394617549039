import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionDetails: [],
  subscriptionBillingCycle: [],
  subscriptionBillingBasis: [],
};

export const slice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionList: (state, action) => {
      state.subscriptionDetails = action.payload;
    },

    setSubscriptionBillingCycle: (state, action) => {
      state.subscriptionBillingCycle = action.payload;
    },

    setSubscriptionBillingBasis: (state, action) => {
      state.subscriptionBillingBasis = action.payload;
    },

    deleteSubscriptionPlan: (state, action) => {
      state.subscriptionDetails = state.subscriptionDetails.filter(
        (item) => item.id !== action.payload
      );
    },

    enableDisableSubscription: (state, action) => {
      const user = action.payload;
      console.log("hiii", user);
      let _newUserList = state.subscriptionDetails;
      if (_newUserList?.length > 0) {
        const _foundUser = _newUserList.findIndex(
          (_user) => _user.id === user.id
        );
        console.log("found user ", _foundUser);
        _newUserList[_foundUser].is_active =
          !_newUserList[_foundUser].is_active;
        state.subscriptionDetails = _newUserList;
      }
    },
  },
});

export const {
  setSubscriptionList,
  setSubscriptionBillingCycle,
  setSubscriptionBillingBasis,
  deleteSubscriptionPlan,
  enableDisableSubscription,
} = slice.actions;

export default slice.reducer;
