import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  printitemsDetails: [],
  printitemTypes: [],
};

export const slice = createSlice({
  name: "printitems",
  initialState,
  reducers: {
    setPrintItemsDetails: (state, action) => {
      state.printitemsDetails = action.payload;
    },

    setPrintItemTypes: (state, action) => {
      state.printitemTypes = action.payload;
    },

    deletePrintItem: (state, action) => {
      state.printitemsDetails = state.printitemsDetails.filter(
        (item) => item.id !== action.payload
      );
    },

    enableDisablePrintItems: (state, action) => {
      const user = action.payload;
      const _newUserList = state.printitemsDetails;
      if (_newUserList?.length > 0) {
        const _foundUser = _newUserList.find((_user) => _user.id === user.id);
        _foundUser.is_active = !_foundUser.is_active;
        state.printitemsDetails = _newUserList;
      }
    },
  },
});

export const {
  setPrintItemsDetails,
  setPrintItemTypes,
  deletePrintItem,
  enableDisablePrintItems,
} = slice.actions;

export default slice.reducer;
