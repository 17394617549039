import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderData: [],
  orderHistory: [],
  orderClientList: [],
  orderPrintItemList: [],
  dtpSingleOrder:{},
  dtpOrderChanged:true,
};

export const slice = createSlice({
  name: "dtpOrder",
  initialState,
  reducers: {
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setOrderHistory: (state, action) => {
      state.orderHistory = action.payload;
    },
    setOrderClientList: (state, action) => {
      state.orderClientList = action.payload;
    },
    setOrderPrintItemList: (state, action) => {
      state.orderPrintItemList = action.payload;
    },
    setDtpSingleorderData: (state, action) => {
      state.dtpSingleOrder= action.payload;
    }, 
    setDtpOrderChanged: (state, action) => {
      state.dtpOrderChanged= action.payload;
    },
  },
});

export const {
  setOrderClientList,
  setOrderData,
  setOrderHistory,
  setOrderPrintItemList,
  setDtpSingleorderData,
  setDtpOrderChanged
} = slice.actions;

export default slice.reducer;
