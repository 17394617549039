import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  canvasJson: {
    paper_type: null,
    side: null,
    canvas_width: null,
    canvas_height: null,
    canvas_width_mm: null,
    canvas_height_mm: null,
    dpi: null,
    contents: {
      canvas_objects: [],
      backgroundColor: null,
      background_image: null,
      background_img_contain: true,
    },
  },
};

export const slice = createSlice({
  name: "canvasJson",
  initialState,
  reducers: {
    setCanvasJson: (state, action) => {
      state.canvasJson = action.payload;
    },
    setPaperType: (state, action) => {
      state.canvasJson.paper_type = action.payload;
    },
    setPaperSide: (state, action) => {
      state.canvasJson.side = action.payload;
    },
    setCanvasBackgroundImage: (state, action) => {
      state.canvasJson.contents.background_image = action.payload;
    },
    setBackgroundColor: (state, action) => {
      state.canvasJson.contents.backgroundColor = action.payload;
    },
    setCanvasObjectProperties: (state, action) => {
      // const updateProperties =
      //   state?.canvasJson?.contents?.canvas_objects?.filter(
      //     (obj) => obj.id !== action.payload.id
      //   );
      // return [...updateProperties, action.payload];
      const existingObjectIndex =
        state?.canvasJson?.contents?.canvas_objects?.findIndex(
          (obj) => obj.id === action.payload.id
        );
      if (existingObjectIndex !== -1) {
        state.canvasJson.contents.canvas_objects[existingObjectIndex] =
          action.payload;
      } else {
        state?.canvasJson?.contents?.canvas_objects?.push(action.payload);
      }
    },
  },
});

export const {
  setBackgroundColor,
  setCanvasBackgroundImage,
  setCanvasJson,
  setCanvasObjectProperties,
  setPaperType,
  setPaperSide,
} = slice.actions;

export default slice.reducer;
