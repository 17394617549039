import React from "react";
import FallbackLoader from "./common/FallbackLoader";
import DashboardLayouts from "../layouts/DashboardLayouts";
import AddNewOrdersLayout from "../layouts/AddNewOrdersLayout";
import OrderDetailsLayout from "../layouts/OrderDetailsLayout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PrintIcon from "@mui/icons-material/Print";
import FontsIcon from "@mui/icons-material/TextFields";

// Login Routes
const Login = React.lazy(() => import("./common/Login"));
const ForgotPassword = React.lazy(() => import("./common/ForgotPassword"));
const CreatePassword = React.lazy(() => import("./common/CreatePassword"));

// DTP Routes
const Dashboard = React.lazy(() => import("./dtp/Details/Dashboard"));
const Orders = React.lazy(() => import("./dtp/Details/Orders"));
const Clients = React.lazy(() => import("./dtp/Details/Clients"));
const Profile = React.lazy(() => import("./dtp/Details/Profile"));
const Fonts = React.lazy(() => import("./dtp/Details/Fonts"));
const OrderDetails = React.lazy(() => import("./dtp/Details/OrderDetails"));
const AddNewOrder = React.lazy(() => import("./dtp/Create/AddNewOrder"));
const AddNewClient = React.lazy(() => import("./dtp/Create/AddNewClient"));
const ClientDetails = React.lazy(() => import("./dtp/Details/ClientDetails"));
const EditClient = React.lazy(() => import("./dtp/Edit/EditClient"));
const EditProfile = React.lazy(() => import("./dtp/Edit/EditProfile"));
const BatchDetails = React.lazy(() => import("./dtp/Details/BatchDetails"));
const ViewTemplate = React.lazy(() => import("./dtp/Details/ViewTemplate"));

// Client Routes
const ClientDashboard = React.lazy(() => import("./client/Details/Dashboard"));
const ClientOrders = React.lazy(() => import("./client/Details/Orders"));
const ClientProfile = React.lazy(() => import("./client/Details/Profile"));
const ClientEditProfile = React.lazy(() => import("./client/Edit/EditProfile"));
const ClientAddNewOrder = React.lazy(() =>
  import("./client/Create/AddNewOrder")
);
const ClientOrderDetails = React.lazy(() =>
  import("./client/Details/ClientOrderDetails")
);
const ClientBatchDetails = React.lazy(() =>
  import("./client/Details/BatchDetails")
);
const ClientViewTemplate = React.lazy(() =>
  import("./client/Details/ViewTemplate")
);
const ClientVerifyTemplate = React.lazy(() =>
  import("./client/Details/VerifyTemplate")
);

// Print-House Routes
const PrintHouseDashboard = React.lazy(() =>
  import("./printHouse/Details/Dashboard")
);
const PrintHouseOrders = React.lazy(() => import("./printHouse/Details/Order"));
const PrintHouseViewBill = React.lazy(() =>
  import("./printHouse/Details/PrintHouseViewBill")
);
const PrintHouseOrderDetails = React.lazy(() =>
  import("./printHouse/Details/PrintHouseOrderDetails")
);
const PrintHouseDtp = React.lazy(() =>
  import("./printHouse/Details/PrintHouseDtp")
);
const PrintHouseBilling = React.lazy(() =>
  import("./printHouse/Details/PrintHouseBilling")
);
const PrintHouseProfile = React.lazy(() =>
  import("./printHouse/Details/PrintHouseProfile")
);
const PrintHouseBatchDetails = React.lazy(() =>
  import("./printHouse/Details/PrintHouseBatchDetails")
);
const AddNewDtp = React.lazy(() => import("./printHouse/Create/AddNewDtp"));
const PrintHouseEditDtp = React.lazy(() =>
  import("./printHouse/Edit/PrintHouseEditDtp")
);
const PrintHouseEditProfile = React.lazy(() =>
  import("./printHouse/Edit/PrintHouseEditProfile")
);
// ./printHouse/Details/PrintHousedtpDetails
const PrintHousedtpDetails = React.lazy(() =>
  import("./printHouse/Details/PrintHousedtpDetails")
);
const PrintHouseViewPrintables = React.lazy(() =>
  import("./printHouse/Details/PrintHouseViewPrintables")
);
const AddPrintables = React.lazy(() =>
  import("./printHouse/Create/AddPrintables")
);

//End User
const EndUserScanQR = React.lazy(() => import("./endUser/EndUserScanQR"));
const EndUserLogin = React.lazy(() => import("./endUser/EndUserLogin"));
const EndUserPreview = React.lazy(() => import("./endUser/EndUserPreview"));
const EndUserPreviewDetails = React.lazy(() =>
  import("./endUser/EndUserPreviewDetails")
);
const EndUserUploadData = React.lazy(() =>
  import("./endUser/EndUserUploadData")
);
const EndUserSuccess = React.lazy(() => import("./endUser/EndUserSuccess"));
const EndUserBackSide = React.lazy(() => import("./endUser/EndUserBackSide"));
const EndUserImagePreview = React.lazy(() =>
  import("./endUser/EndUserImagePreview")
);

// Super Admin Routes
const SuperAdminDashboard = React.lazy(() =>
  import("./superadmin/Details/Dashboard")
);
const SuperAdminUsers = React.lazy(() => import("./superadmin/Details/Users"));
const SuperAdminBilling = React.lazy(() =>
  import("./superadmin/Details/Billing")
);
const SuperAdminSubscriptionPlans = React.lazy(() =>
  import("./superadmin/Details/SubscriptionPlans")
);
const SuperAdminPrintItems = React.lazy(() =>
  import("./superadmin/Details/PrintItems")
);
const SuperAdminAddNewUser = React.lazy(() =>
  import("./superadmin/Create/AddUser")
);
const SuperAdminUserDetails = React.lazy(() =>
  import("./superadmin/Details/UserDetails")
);
const SuperAdminEditDTP = React.lazy(() =>
  import("./superadmin/Edit/EditDTPUser")
);
const SuperAdminEditPrintHouse = React.lazy(() =>
  import("./superadmin/Edit/EditPrintHouseUser")
);
const SuperAdminEditClient = React.lazy(() =>
  import("./superadmin/Edit/EditClientUser")
);
const SuperAdminEditBill = React.lazy(() =>
  import("./superadmin/Edit/EditBill")
);
const SuperAdminViewBill = React.lazy(() =>
  import("./superadmin/Details/ViewBill")
);
const SuperAdminAddNewPlan = React.lazy(() =>
  import("./superadmin/Create/AddNewPlan")
);
const SuperAdminEditPlan = React.lazy(() =>
  import("./superadmin/Edit/EditPlan")
);
const SuperAdminAddPrintItems = React.lazy(() =>
  import("./superadmin/Create/AddPrintItems")
);
const SuperadminPrintItemDetails = React.lazy(() =>
  import("./superadmin/Details/PrintItemDetails")
);
const SuperAdminEditPrintItems = React.lazy(() =>
  import("./superadmin/Edit/EditPrintItems")
);

// Auth Screen
const unAuthRoutes = {
  login: {
    key: "login",
    paths: ["/", "/login"],
    element: <Login />,
    protected: false,
    // label: "Login",
    docTitle: "MyPrintBot - Login",
    // icon: <PeopleRoundedIcon />,
    suspense: true,
    fallback: <FallbackLoader />,
    // dashboardListed: false,
  },
  forgotPassword: {
    key: "forgotPassword",
    paths: ["/forgotPassword"],
    element: <ForgotPassword />,
    protected: false,
    // label: "Forgot Password",
    docTitle: "Forgot Password",
    suspense: true,
    fallback: <FallbackLoader />,
    // dashboardListed: false,
  },
  createPassword: {
    key: "createPassword",
    paths: ["/createPassword"],
    element: <CreatePassword />,
    protected: false,
    // label: "Create Password",
    docTitle: "Create Password",
    suspense: true,
    fallback: <FallbackLoader />,
    // dashboardListed: false,
  },
  enduser: {
    key: "enduser",
    paths: ["/enduser", "/enduser/:id"],
    element: <EndUserScanQR />,
    protected: false,
    // label: "Create Password",
    docTitle: "Scan QR",
    suspense: true,
    fallback: <FallbackLoader />,
    // dashboardListed: false,
  },
  endUserLogin: {
    key: "endUserLogin",
    protected: false,
    paths: ["/endUser/login"],
    element: <EndUserLogin />,
    docTitle: "Login",
    suspense: true,
    label: "Login",
    // dashboardListed: true,
    fallback: <FallbackLoader />,
  },
};
export const unAuthRoutesList = Object.entries(unAuthRoutes);

// DTP
const dtpDashboardRoutes = {
  // Uncomment when Dashborad story is available
  // dtpDashboard: {
  //   key: "dashboard",
  //   protected: true,
  //   paths: ["/", "/dtp"],
  //   element: <Dashboard />,
  //   docTitle: "Dashboard",
  //   suspense: true,
  //   label: "Dashboard",
  //   dashboardListed: true,
  //   fallback: <FallbackLoader />,
  //   // component: <DashboardLayouts />,
  //   icon: <DashboardIcon />,
  // },
  dtpOrders: {
    key: "orders",
    protected: true,
    paths: ["/", "/orders"], // Remove "/" when dashboard story is available and Uncomment from Navbar and AddNewOrderLayout
    element: <Orders />,
    docTitle: "Orders",
    suspense: true,
    label: "Orders",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <ArticleIcon />,
  },
  clients: {
    key: "clients",
    protected: true,
    paths: ["/clients"],
    element: <Clients />,
    docTitle: "Clients",
    suspense: true,
    label: "Clients",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <PersonIcon />,
  },
  fonts: {
    key: "fonts",
    protected: true,
    paths: ["/fonts"],
    element: <Fonts />,
    docTitle: "Fonts",
    suspense: false,
    label: "Fonts",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <FontsIcon />,
  },
  profile: {
    key: "profile",
    protected: true,
    paths: ["/profile"],
    element: <Profile />,
    docTitle: "Profile",
    suspense: false,
    label: "Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <RecentActorsIcon />,
  },
};
const dtpRoutes = { ...dtpDashboardRoutes };
export const dtpRoutesList = Object.entries(dtpRoutes);
export const dtpDashboardRoutes_as_array = Object.entries(dtpDashboardRoutes);

const dtpAddNewOrderRoutes = {
  dtpAddNewOrder: {
    key: "addNewOrder",
    protected: true,
    paths: ["/addNewOrder"],
    element: <AddNewOrder />,
    docTitle: "Add New Order",
    suspense: true,
    label: "Add New Order",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  dtpAddNewClient: {
    key: "addNewClient",
    protected: true,
    paths: ["/addNewClient"],
    element: <AddNewClient />,
    docTitle: "Add New Client",
    suspense: true,
    label: "Add New Client",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  editClientDetails: {
    key: "editClientDetails",
    protected: true,
    paths: ["/editclient", "/editclient/:id"],
    element: <EditClient />,
    docTitle: "Edit Client",
    suspense: true,
    label: "Edit Client",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  editProfileDetails: {
    key: "editProfileDetails",
    protected: true,
    paths: ["/editProfileDetails"],
    element: <EditProfile />,
    docTitle: "Edit Profile",
    suspense: true,
    label: "Edit Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
};
const dtpAddNew = { ...dtpAddNewOrderRoutes };
export const dtpAddNewOrderList = Object.entries(dtpAddNew);

const dtpOrderDetails = {
  orderDetails: {
    key: "orderDetails",
    protected: true,
    paths: ["/orderdetails/:templateStatus/:order_id"],
    element: <OrderDetails />,
    docTitle: "Order Details",
    suspense: true,
    label: "Order Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  clientDetails: {
    key: "clientDetails",
    protected: true,
    paths: ["/clientDetails", "/client/details/:id"],
    element: <ClientDetails />,
    docTitle: "Client Details",
    suspense: true,
    label: "Client Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  batchDetails: {
    key: "batchDetails",
    protected: true,
    paths: ["/batchdetails", "/batchdetails/:id"],
    element: <BatchDetails />,
    docTitle: "Batch Details",
    suspense: true,
    label: "Batch Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  viewtemplate: {
    key: "viewtemplate",
    protected: true,
    paths: ["/viewtemplates", "/viewtemplate/:id"],
    element: <ViewTemplate />,
    docTitle: "View Templates",
    suspense: true,
    label: "View Templates",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
};
export const dtpOrderDetailsList = Object.entries(dtpOrderDetails);

// PrintHouse
const printHouseDashBoardRoutes = {
  // Uncomment when Dashboard story is available
  // printHouseDashBoard: {
  //   key: "printHouseDashboard",
  //   protected: true,
  //   paths: ["/", "/printHouse"],
  //   element: <PrintHouseDashboard />,
  //   docTitle: "Dashboard",
  //   suspense: true,
  //   label: "Dashboard",
  //   dashboardListed: true,
  //   fallback: <FallbackLoader />,
  //   component: <DashboardLayouts />,
  //   icon: <DashboardIcon />,
  // },
  printHouseOrders: {
    key: "printHouseOrder",
    protected: true,
    paths: ["/", "/printHouse/order"], // Remove "/" when dashboard story is available
    element: <PrintHouseOrders />,
    docTitle: "Orders",
    suspense: true,
    label: "Order",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <ArticleIcon />,
  },
  printHouseDtp: {
    key: "printHouseDtp",
    protected: true,
    paths: ["/printHouse/dtp"],
    element: <PrintHouseDtp />,
    docTitle: "DTP",
    suspense: true,
    label: "DTP",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <PersonIcon />,
  },
  printHouseBilling: {
    key: "printHouseBilling",
    protected: true,
    paths: ["/printHouse/billing"],
    element: <PrintHouseBilling />,
    docTitle: "Billing",
    suspense: true,
    label: "Billing",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <ArticleIcon />,
  },
  printHouseProfile: {
    key: "printHouseProfile",
    protected: true,
    paths: ["/printHouse/profile"],
    element: <PrintHouseProfile />,
    docTitle: "Profile",
    suspense: true,
    label: "Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <RecentActorsIcon />,
  },
};
const printHouseRoutes = { ...printHouseDashBoardRoutes };
export const printHouseRoutesList = Object.entries(printHouseRoutes);
export const printHouseRoutes_as_array = Object.entries(
  printHouseDashBoardRoutes
);

const printHouseOrderDetails = {
  orderDetails: {
    key: "printHouseorderDetails",
    protected: true,
    paths: ["/printHouse/orderdetails/:templateStatus/:order_id"],
    element: <PrintHouseOrderDetails />,
    docTitle: "Order Details",
    suspense: true,
    label: "Order Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  printHouseDtpDetails: {
    key: "printHouseDtpDetails",
    protected: true,
    paths: ["/printHouse/dtpDetails/", "/printHouse/dtpDetails/:id"],
    element: <PrintHousedtpDetails />,
    docTitle: "Client Details",
    suspense: true,
    label: "Client Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  printHouseAddPrintable: {
    key: "printHouseAddPrintable",
    protected: true,
    paths: ["/printHouse/addPrintable"],
    element: <AddPrintables />,
    docTitle: "Add Printable",
    suspense: true,
    label: "Add Printable",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  printHouseBatchDetails: {
    key: "printHousebatchDetails",
    protected: true,
    paths: [
      "/printHouse/order/batchdetails",
      "/printHouse/order/batchdetails/:id",
    ],
    element: <PrintHouseBatchDetails />,
    docTitle: "Batch Details",
    suspense: true,
    label: "Batch Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
};
export const printHouseOrderDetailsList = Object.entries(
  printHouseOrderDetails
);
const printHouseAddNewOrderRoutes = {
  printHouseAddNewDtp: {
    key: "printHouseAddNewDtp",
    protected: true,
    paths: ["/printHouse/addNewdtp"],
    element: <AddNewDtp />,
    docTitle: "Add New DTP",
    suspense: true,
    label: "Add New DTP",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },

  printHouseEditDtpDetails: {
    key: "printHouseEditDtpDetails",
    protected: true,
    paths: ["/printHouse/editDtp", "/printHouse/editDtp/:id"],
    element: <PrintHouseEditDtp />,
    docTitle: "Edit DTP",
    suspense: true,
    label: "Edit DTP",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  printHouseViewBill: {
    key: "viewBill",
    protected: true,
    paths: ["/printHouse/viewbill", "/printHouse/viewbill/:id"],
    element: <PrintHouseViewBill />,
    docTitle: "View Bill",
    suspense: true,
    label: "View Bill",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },

  printHouseViewPrintables: {
    key: "viewPrintables",
    protected: true,
    paths: ["/printHouse/viewPrintables", "/printHouse/viewPrintables/:id"],
    element: <PrintHouseViewPrintables />,
    docTitle: "View Bill",
    suspense: true,
    label: "View Bill",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },

  printHouseEditProfileDetails: {
    key: "printHouseEditProfileDetails",
    protected: true,
    paths: ["/printHouse/editProfileDetails"],
    element: <PrintHouseEditProfile />,
    docTitle: "Edit Profile",
    suspense: true,
    label: "Edit Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
};
const printHouseAddNew = { ...printHouseAddNewOrderRoutes };
export const printHouseAddNewOrderList = Object.entries(printHouseAddNew);

// Client
const clientDashboardRoutes = {
  // Uncomment when Dashboard story is available
  // clientDashboard: {
  //   key: "clientDashboard",
  //   protected: true,
  //   paths: ["/", "/client"],
  //   element: <ClientDashboard />,
  //   docTitle: "Dashboard",
  //   suspense: true,
  //   label: "Dashboard",
  //   dashboardListed: true,
  //   fallback: <FallbackLoader />,
  //   component: <DashboardLayouts />,
  //   icon: <DashboardIcon />,
  // },
  clientOrder: {
    key: "clientOrder",
    protected: true,
    paths: ["/", "/client/order"], // Remove "/" when Dashboard story is available
    element: <ClientOrders />,
    docTitle: "Orders",
    suspense: true,
    label: "Order",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <ArticleIcon />,
  },
  clientProfile: {
    key: "clientProfile",
    protected: true,
    paths: ["/client/profile"],
    element: <ClientProfile />,
    docTitle: "Profile",
    suspense: true,
    label: "Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <RecentActorsIcon />,
  },
};
const clientRoutes = { ...clientDashboardRoutes };
export const clientRoutesList = Object.entries(clientRoutes);
export const clientDashboardRoutes_as_array = Object.entries(
  clientDashboardRoutes
);

const clientEditRoutes = {
  clientEditProfile: {
    key: "editProfile",
    protected: true,
    paths: ["/client/editprofile"],
    element: <ClientEditProfile />,
    docTitle: "Edit Profile",
    suspense: true,
    label: "Edit Profile",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  clientAddNewOrder: {
    key: "addNewOrder",
    protected: true,
    paths: ["/orders/addneworder"],
    element: <ClientAddNewOrder />,
    docTitle: "Add New Order",
    suspense: true,
    label: "Add New Order",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
};
const clientEdit = { ...clientEditRoutes };
export const clientEditList = Object.entries(clientEdit);

const clientDetails = {
  clientOrderDetails: {
    key: "orderDetails",
    protected: true,
    paths: ["/client/orderdetails/:templateStatus/:order_id"],
    element: <ClientOrderDetails />,
    docTitle: "Order Details",
    suspense: true,
    label: "Order Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  clientBatchDetails: {
    key: "clientBatchDetails",
    protected: true,
    paths: ["/client/orders/batchdetails", "/client/orders/batchdetails/:id"],
    element: <ClientBatchDetails />,
    docTitle: "Batch Details",
    suspense: true,
    label: "Batch Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  clientViewTemplate: {
    key: "clientViewTemplate",
    protected: true,
    paths: ["/client/viewtemplate", "/client/viewtemplate/:id"],
    element: <ClientViewTemplate />,
    docTitle: "View Template",
    suspense: true,
    label: "View Template",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  clientVerifyTemplate: {
    key: "clientVerifyTemplate",
    protected: true,
    paths: ["/client/verifytemplate", "/client/verifytemplate/:id"],
    element: <ClientVerifyTemplate />,
    docTitle: "Verify Template",
    suspense: true,
    label: "Verify Template",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
};
export const clientOrderDetailsList = Object.entries(clientDetails);

// End User
const endUserRoutes = {
  endUserScanQr: {
    key: "enduser",
    protected: false,
    paths: ["/enduser/:id", "/enduser"],
    element: <EndUserScanQR />,
    docTitle: "Scan QR",
    suspense: true,
    label: "Scan QR",
    // dashboardListed: true,
    fallback: <FallbackLoader />,
  },
  endUserLogin: {
    key: "endUserLogin",
    protected: true,
    paths: ["/endUser/login"],
    element: <EndUserLogin />,
    docTitle: "Login",
    suspense: true,
    label: "Login",
    // dashboardListed: true,
    fallback: <FallbackLoader />,
  },
  endUserPreview: {
    key: "endUserPreview",
    protected: true,
    paths: ["/endUser/preview"],
    element: <EndUserPreview />,
    docTitle: "Login",
    suspense: true,
    label: "Login",
    // dashboardListed: true,
    fallback: <FallbackLoader />,
  },
  endUserPreviewDetails: {
    key: "endUserPreviewDetails",
    protected: true,
    paths: ["/endUser/preview/details"],
    element: <EndUserPreviewDetails />,
    docTitle: "Login",
    suspense: true,
    label: "Login",
    // dashboardListed: true,
    fallback: <FallbackLoader />,
  },
  endUserUploadData: {
    key: "endUserUploadData",
    protected: true,
    paths: ["/enduser/upload-data"],
    element: <EndUserUploadData />,
    docTitle: "Upload Data",
    suspense: true,
    label: "Upload Data",
    fallback: <FallbackLoader />,
  },
  endUserSuccess: {
    key: "endUserSucess",
    protected: true,
    paths: ["/enduser/success"],
    element: <EndUserSuccess />,
    doctTitle: "Success",
    suspense: true,
    label: "Success",
    fallback: <FallbackLoader />,
  },
  endUserBackSide: {
    key: "endUserBackSide",
    protected: true,
    paths: ["/enduser/backside"],
    element: <EndUserBackSide />,
    doctTitle: "Back Side",
    suspense: true,
    label: "Back Side",
    fallback: <FallbackLoader />,
  },
  endUserImagePreview: {
    key: "endUserImagePreview",
    protected: true,
    paths: ["/enduser/imagepreview"],
    element: <EndUserImagePreview />,
    docTitle: "Image Preview",
    suspense: true,
    label: "Image Preview",
    fallback: <FallbackLoader />,
  },
};
const endUser = { ...endUserRoutes };
export const endUserRoutesList = Object.entries(endUser);
export const endUserRoutes_as_array = Object.entries(endUserRoutes);

// Super Admin
const superAdminDashboardRoutes = {
  // Uncomment when Dashboard story is available
  // superAdminDashboard: {
  //   key: "superAdminDashboard",
  //   protected: true,
  //   paths: ["/", "/superadmin"],
  //   element: <SuperAdminDashboard />,
  //   docTitle: "Dashboard",
  //   suspense: true,
  //   label: "Dashboard",
  //   dashboardListed: true,
  //   fallback: <FallbackLoader />,
  //   component: <DashboardLayouts />,
  //   icon: <DashboardIcon />,
  // },
  superAdminUser: {
    key: "superAdminUser",
    protected: true,
    paths: ["/", "/superadmin/user"], // Remove "/" when Dashboard story is available
    element: <SuperAdminUsers />,
    docTitle: "Users",
    suspense: true,
    label: "Users",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <PersonIcon />,
  },
  superAdminBilling: {
    key: "superAdminBilling",
    protected: true,
    paths: ["/superadmin/billing"],
    element: <SuperAdminBilling />,
    docTitle: "Billing",
    suspense: true,
    label: "Billing",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <ArticleIcon />,
  },
  superAdminPlans: {
    key: "superAdminPlans",
    protected: true,
    paths: ["/superadmin/plans"],
    element: <SuperAdminSubscriptionPlans />,
    docTitle: "Subscription Plans",
    suspense: true,
    label: "Subscription Plans",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <DashboardIcon />,
  },
  superAdminPrint: {
    key: "superAdminPrint",
    protected: true,
    paths: ["/superadmin/print-items"],
    element: <SuperAdminPrintItems />,
    docTitle: "Super Admin Print Items",
    suspense: true,
    label: " Print Items",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
    icon: <PrintIcon />,
  },
};
const superAdminRoutes = { ...superAdminDashboardRoutes };
export const superAdminRoutesList = Object.entries(superAdminRoutes);
export const superAdminDashboardArray_as_array = Object.entries(
  superAdminDashboardRoutes
);

const superAdminAddRoutes = {
  superAdminAddNewUser: {
    key: "addNewUser",
    protected: true,
    paths: ["/users/addnewuser"],
    element: <SuperAdminAddNewUser />,
    docTitle: "Add New User",
    suspense: true,
    label: "Add New User",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditDTP: {
    key: "editUser",
    protected: true,
    paths: ["/dtp/edituser", "/dtp/edituser/:id"],
    element: <SuperAdminEditDTP />,
    docTitle: "Edit DTP",
    suspense: true,
    label: "Edit DTP",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditPrinthouse: {
    key: "editPrinthouse",
    protected: true,
    paths: ["/printhouse/edituser", "/printhouse/edituser/:id"],
    element: <SuperAdminEditPrintHouse />,
    docTitle: "Edit Printhouse",
    suspense: true,
    label: "Edit Printhouse",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditClient: {
    key: "editClient",
    protected: true,
    paths: ["/client/edituser", "/client/edituser/:id"],
    element: <SuperAdminEditClient />,
    docTitle: "Edit Client",
    suspense: true,
    label: "Edit Client",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditBill: {
    key: "editBill",
    protected: true,
    paths: ["/billing/editbill/", "/billing/editbill/:billing_id"],
    element: <SuperAdminEditBill />,
    docTitle: "Edit Bill",
    suspense: true,
    label: "Edit Bill",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminViewBill: {
    key: "viewBill",
    protected: true,
    paths: ["/history/viewbill", "/history/viewbill/:billing_id"],
    element: <SuperAdminViewBill />,
    docTitle: "View Bill",
    suspense: true,
    label: "View Bill",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminAddNewPlan: {
    key: "addNewPlan",
    protected: true,
    paths: ["/plans/addnewplan"],
    element: <SuperAdminAddNewPlan />,
    docTitle: "Add New Plan",
    suspense: true,
    label: "Add New Plan",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditPlan: {
    key: "editPlan",
    protected: true,
    paths: ["/plans/editplan", "/plans/editplan/:id"],
    element: <SuperAdminEditPlan />,
    docTitle: "Edit Plan",
    suspense: true,
    label: "Edit Plan",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminEditPrintItems: {
    key: "editPrintItems",
    protected: true,
    paths: ["/printitems/editprintitems", "/printitems/editprintitems/:id"],
    element: <SuperAdminEditPrintItems />,
    docTitle: "Edit Print Items",
    suspense: true,
    label: "Edit Print Items",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
  superAdminAddPrintItems: {
    key: "addPrintItems",
    protected: true,
    paths: ["/print-items/addprintitems"],
    element: <SuperAdminAddPrintItems />,
    docTitle: "Add Print Items",
    suspense: true,
    label: "Add Print Items",
    dashboardListed: false,
    fallback: <FallbackLoader />,
    component: <AddNewOrdersLayout />,
  },
};
const superAdminAdd = { ...superAdminAddRoutes };
export const superAdminAddNewList = Object.entries(superAdminAdd);

const superAdminDetails = {
  superAdminUserDetails: {
    key: "userDetails",
    protected: true,
    location: "/superadmin/user",
    paths: ["/superadmin/userdetails/", "/superadmin/userdetails/:id"],
    element: <SuperAdminUserDetails />,
    docTitle: "User Details",
    suspense: true,
    label: "User Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
  superAdminPrintItemDetails: {
    key: "printItemDetails",
    protected: true,
    paths: ["/printitem/details", "/printitem/details/:id"],
    element: <SuperadminPrintItemDetails />,
    docTitle: "Print Item Details",
    suspense: true,
    label: "Print Item Details",
    dashboardListed: true,
    fallback: <FallbackLoader />,
    component: <DashboardLayouts />,
  },
};
export const superAdminDetailsList = Object.entries(superAdminDetails);

const routes = {
  // Login
  ...unAuthRoutes,
  // DTP
  ...dtpRoutes,
  ...dtpAddNewOrderRoutes,
  ...dtpOrderDetails,
  // Print House
  ...printHouseRoutes,
  ...printHouseAddNewOrderRoutes,
  ...printHouseOrderDetails,
  // Client
  ...clientRoutes,
  ...clientEditRoutes,
  ...clientDetails,
  // End User
  ...endUserRoutes,
  // Super Admin
  ...superAdminRoutes,
  ...superAdminAddRoutes,
  ...superAdminDetails,
};

export {
  dtpRoutes,
  unAuthRoutes,
  dtpAddNewOrderRoutes,
  dtpOrderDetails,
  clientRoutes,
  clientEditRoutes,
  clientDetails,
  superAdminRoutes,
  superAdminAddRoutes,
  superAdminDetails,
};

export default routes;
