import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingDetails: [],
  billingHistory: [],
};

export const slice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBillingData: (state, action) => {
      state.billingDetails = action.payload;
    },
    setBillingHistory: (state, action) => {
      state.billingHistory = action.payload;
    },
  },
});

export const { setBillingData, setBillingHistory } = slice.actions;

export default slice.reducer;
