import React, { useState, Suspense } from "react";
import { Box } from "@mui/material";
import Sidebar from "../components/Sidebar";
import { Outlet, useLocation } from "react-router-dom";

function OrderDetailsLayout() {
  const location = useLocation();
  console.log(location);
  const [selectedCategory, setSelectedCategory] = useState(location.pathname);
  return (
    <>
      <Box>
        <Box
          sx={{
            px: { sx: 0, md: 0 },
            backgroundColor: "#283C92",
          }}
        >
          <Sidebar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          bgcolor: "#F5F6FA",
        }}
        marginLeft={{ xs: 0, md: "240px" }}
        marginTop="90px"
        p={2}
      >
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

export default OrderDetailsLayout;
