import axios from "axios";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_URL;
// http://4.240.81.241:8080

// Prod
// const REACT_APP_API_BASE_URL = "https://backend-api.myprintbot.com";

// const REACT_APP_API_BASE_URL = "http://91.203.134.255:8080";
// new deployment URL for testing (Email : myprint@print.com , pass : 123456) Date: 10/6/23.

const openAxiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

const securedAxiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

const endUserAxiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

export const setXApiKey = (key) => {
  openAxiosInstance.defaults.headers.common["x-api-key"] = `${key}`;
};

export const setAuthorizationToken = (token) => {
  securedAxiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeAuthorizationToken = () => {
  delete securedAxiosInstance.defaults.headers.Authorization;
};

export const setEndUserToken = (token) => {
  endUserAxiosInstance.defaults.headers.Authorization = token;
};

export { endUserAxiosInstance, openAxiosInstance, securedAxiosInstance };
