import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Drawer, List } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import {
  dtpDashboardRoutes_as_array,
  clientDashboardRoutes_as_array,
  superAdminDashboardArray_as_array,
  printHouseRoutes_as_array,
  endUserRoutes_as_array,
} from "../routes/routes";
import { useSelector } from "react-redux";
import { updateNonNullChain } from "typescript";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  drawerOpen?: any;
}

function Sidebar(
  {
    selectedCategory,
    setSelectedCategory,
  }: { selectedCategory: string; setSelectedCategory: any },
  props: Props
) {
  const { userType } = useSelector((state: any) => state.auth);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const [dashboradArray, setDashboardArray] = useState<any[]>([]);

  const getUserType = async () => {
    switch (userType) {
      case 1:
        setDashboardArray(superAdminDashboardArray_as_array);
        break;
      case 2:
        setDashboardArray(printHouseRoutes_as_array);
        break;
      case 3:
        setDashboardArray(dtpDashboardRoutes_as_array);
        console.log(dashboradArray);
        break;
      case 4:
        setDashboardArray(clientDashboardRoutes_as_array);
        break;
      case 5:
        setDashboardArray(endUserRoutes_as_array);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getUserType();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "#283C92",
        flex: 1,
        display: ` ${userType === 5 ? "none" : "flex"}`,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Toolbar />
      <List sx={{ display: "flex", flexDirection: "column" }}>
        {dashboradArray.map((category) => {
          const route = category[1];
          const { label, icon, paths } = route;
          return (
            <button
              className="category-btn"
              onClick={() => {
                setSelectedCategory(paths[0]);
                setMobileOpen(!mobileOpen);
                navigate(paths[0]);
                console.log(paths[0]);
              }}
              style={{
                background: paths[0] === selectedCategory ? "#FFFFFF" : "",
                color: "#283C92",
              }}
              key={label}
            >
              <span
                style={{
                  color: paths[0] === selectedCategory ? "#283C92" : "#FFFFFF",
                  marginRight: "15px",
                }}
              >
                {icon}
              </span>
              <span
                style={{
                  color: paths[0] === selectedCategory ? "#283C92" : "#FFFFFF",
                }}
              >
                {label}
              </span>
            </button>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return userType === 5 ? null : (
    <Box>
      <Navbar drawerOpen={handleDrawerToggle} />
      <Box>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  window: PropTypes.any,
};

export default Sidebar;
