import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingDetails: [],
};

export const slice = createSlice({
  name: "printHouseBilling",
  initialState,
  reducers: {
    setPrintHouseBillingData: (state, action) => {
      state.billingDetails = action.payload;
    }
  },
});

export const { setPrintHouseBillingData} = slice.actions;

export default slice.reducer;
