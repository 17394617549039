import { toast } from "react-hot-toast";

/**
 *
 * @param {string} message
 * @param {boolean} success
 */
const createPromiseToast = ({ success, message }) => {
  var successMessage = success ? message : null;
  var errorMessage = !success ? message : null;
  toast.promise(
    new Promise((resolve, reject) => {
      if (success) {
        resolve(console.log);
      } else {
        reject(console.error);
      }
    }),
    {
      loading: "Loading",
      success: successMessage,
      error: errorMessage,
    },
    {
      style: {
        minWidth: "250px",
      },
      success: {
        duration: 3000,
      },
      error: {
        duration: 3000,
      },
    }
  );
};

export { createPromiseToast };
