import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dtpData: [],
  singleDTPDetails:{},
};

export const slice = createSlice({
  name: "printHouseDtp",
  initialState,
  reducers: {
    setPrintHouseDtpList:(state,action)=>{
        state.dtpData = action.payload;
    },
    enableDisableDTP: (state, action) => {
      const user = action.payload;
      const _newUserList = state.dtpData;
      if (_newUserList?.length > 0) {
        const _foundUser = _newUserList.find((_user) => _user.id === user.id);
        _foundUser.is_active = !_foundUser.is_active;
        state.dtpData = _newUserList;
      }
    },
    deleteDTP: (state, action) => {
      state.dtpData = state.dtpData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const { setPrintHouseDtpList ,enableDisableDTP,deleteDTP} = slice.actions;

export default slice.reducer;