import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { unAuthRoutes } from "../routes";
import logo from "../../assets/logo.png";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../components/TextField";
import { api } from "../../api/apis";
import { createPromiseToast } from "../../utils/toast";
import { useDispatch } from "react-redux";
import { forgot } from "../../features/forgotPassword.slice";

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value: any) => {
    console.log(value);
    setLoading(true);
    await api
      .forgotPassword(value.email)
      .then(({ data }) => {
        if (data.result) {
          dispatch(forgot(value.email));
          navigate(unAuthRoutes.createPassword.paths[0]);
        }
        return {
          success: true,
          message: data.message,
        };
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);

        return {
          success: false,
          message: err?.response?.data?.errors[0] ?? err?.message ?? err ?? "",
        };
      })
      .then((toastData: any) => {
        console.log(toastData);
        createPromiseToast(toastData);
      });
  };

  const validate = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format")
      .matches(/[a-z]|| [A-Z]/, "2")
      .trim("no"),
  });

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <Box
        sx={{
          height: { xs: "auto", md: "100dvh" },
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img className="login-logo" src={logo} width="60%" />
      </Box>
      <Box
        sx={{
          height: { xs: "90dvh", md: "100dvh" },
          display: "flex",
          width: "100%",
          backgroundColor: "#283c92",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            marginX: { md: "8rem", sm: "10rem", xs: "5rem" },
            transition: "all 0.3s",
          }}
        >
          <Typography
            variant="h5"
            color="#fff"
            textAlign="center"
            marginBottom={2}
          >
            Forgot Password
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => (
              <Form autoComplete="off" action="" onSubmit={formik.handleSubmit}>
                <TextField
                  label="Email"
                  className="textinput"
                  placeholder="demo@email.com"
                  onChange={(e: any) => {
                    formik.setFieldTouched("email", true, true);
                    formik.setFieldValue("email", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  id="email"
                  name="email"
                  type="email"
                  color="true"
                />
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                    gap: "2rem",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate(-1)}
                    sx={{
                      backgroundColor: "#fff",
                      marginY: "10px",
                      color: "#283c92",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      width: "200px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      backgroundColor: "#fff",
                      marginY: "10px",
                      color: "#283c92",
                      textTransform: "capitalize",
                      boxShadow: "none",
                      width: "200px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    {loading === true ? (
                      <CircularProgress
                        size={26}
                        style={{ color: "#283C92" }}
                      />
                    ) : (
                      "Get OTP"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Stack>
  );
}

export default ForgotPassword;
