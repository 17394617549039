import React from "react";
import { Stack, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { dtpRoutes } from "../routes/routes";
import LogoutButton from "./LogoutButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.png";

import { useSelector } from "react-redux";

function Navbar({ drawerOpen }: { drawerOpen: any }) {
  const { userType } = useSelector((state: any) => state.auth);

  return userType === 5 ? null : (
    <Stack
      direction="row"
      alignItems="center"
      p={2}
      zIndex={2000}
      sx={{
        position: "fixed",
        backgroundColor: "#FFFFFF",
        top: "0px",
        // width: "-webkit-fill-available",
        justifyContent: "space-between",
        width:"98%"
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={drawerOpen}
        sx={{ mr: 2, display: { md: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Link
        // to={dtpRoutes.dtpDashboard.paths[0]}
        to="/" // Remove this and Uncomment 👆 when Dashboard story is available
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          src={logo}
          alt="logo"
          height={60}
          style={{ transition: "all 0.3s ease" }}
        />
      </Link>
      <LogoutButton />
    </Stack>
  );
}

export default Navbar;
